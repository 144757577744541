exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactivos-js": () => import("./../../../src/pages/interactivos.js" /* webpackChunkName: "component---src-pages-interactivos-js" */),
  "component---src-pages-obras-js": () => import("./../../../src/pages/obras.js" /* webpackChunkName: "component---src-pages-obras-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-interactivo-page-js": () => import("./../../../src/templates/InteractivoPage.js" /* webpackChunkName: "component---src-templates-interactivo-page-js" */),
  "component---src-templates-obra-page-js": () => import("./../../../src/templates/ObraPage.js" /* webpackChunkName: "component---src-templates-obra-page-js" */),
  "component---src-templates-obras-page-js": () => import("./../../../src/templates/ObrasPage.js" /* webpackChunkName: "component---src-templates-obras-page-js" */)
}

